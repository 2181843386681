import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Map, { NavigationControl } from 'react-map-gl'
import { CaoOfficeMarker, MapHandler } from './MapHandler'
import { CaoPopup, DeviceInfo } from './CaoPopup'
import {
  getAverageLatitude,
  getAverageLongitude,
  getLatitude,
  getLongitude,
  simpleDistance,
} from '../utils/calculations'
import { useCaoDeviceSettingsInfos } from '../hooks/useCaoDeviceSettingsInfos'
import { CircularProgress, Typography } from '@mui/material'
import { useCaoDeviceDatas } from '../common-code-react/hooks/useCaoDeviceDatas'

export default function MapView(): JSX.Element {
  const { data: caoDeviceDatas, error: caoDeviceDatasError } = useCaoDeviceDatas(
    process.env.REACT_APP_CAO_SENSOR_API || '',
    process.env.REACT_APP_CAO_SENSOR_API_KEY || '',
  )
  const { data: deviceSettingsInfos } = useCaoDeviceSettingsInfos()

  const [popupInfo, setPopupInfo] = useState<DeviceInfo | DeviceInfo[] | null>(null)

  const [caoOfficeMarkers, setCaoOfficeMarkers] = useState<CaoOfficeMarker[]>([])

  useEffect(() => {
    if (caoDeviceDatas && deviceSettingsInfos) {
      // Create device infos of the non hidden devices
      const deviceInfos: DeviceInfo[] = caoDeviceDatas.reduce((result, deviceData) => {
        const settingsInfo = deviceSettingsInfos.find(
          (deviceSettingInfo) => deviceSettingInfo.deviceId === `${deviceData.sensorId}`,
        )
        if (settingsInfo && !settingsInfo.deviceSettings.hiddenInfo?.hidden) {
          result.push({
            deviceSettings: settingsInfo.deviceSettings,
            caoDeviceData: deviceData,
          } as DeviceInfo)
        }
        if (!settingsInfo) {
          result.push({
            caoDeviceData: deviceData,
          } as DeviceInfo)
        }
        return result
      }, [] as DeviceInfo[])

      // Group all devices that are very close to each other
      const alreadyInserted: DeviceInfo[] = []
      const groupedDevices: DeviceInfo[][] = deviceInfos.reduce((result, deviceInfo, outerIndex) => {
        if (!alreadyInserted.includes(deviceInfo)) {
          const close: DeviceInfo[] = deviceInfos.reduce((result, otherDeviceInfo, innerIndex) => {
            if (outerIndex < innerIndex && getLatitude(otherDeviceInfo) !== 0 && getLongitude(otherDeviceInfo) !== 0) {
              const distance = simpleDistance(
                getLatitude(deviceInfo),
                getLongitude(deviceInfo),
                getLatitude(otherDeviceInfo),
                getLongitude(otherDeviceInfo),
              )
              if (distance < 100) {
                result.push(otherDeviceInfo)
              }
            }
            return result
          }, [] as DeviceInfo[])
          if (close.length > 0) {
            const group = [deviceInfo, ...close]
            result.push(group)
            alreadyInserted.push(...group)
          }
        }

        if (!alreadyInserted.includes(deviceInfo) && getLatitude(deviceInfo) !== 0 && getLongitude(deviceInfo) !== 0) {
          result.push([deviceInfo])
        }
        return result
      }, [] as DeviceInfo[][])

      const individualMarkers: CaoOfficeMarker[] = []
      const groupedMarkers: CaoOfficeMarker[] = []

      groupedDevices.forEach((group) => {
        if (group.length === 1) {
          individualMarkers.push({
            lat: getLatitude(group[0]),
            lng: getLongitude(group[0]),
            setPopupInfo: () => setPopupInfo(group[0]),
          })
        }
        if (group.length > 1) {
          groupedMarkers.push({
            lat: getAverageLatitude(group),
            lng: getAverageLongitude(group),
            setPopupInfo: () => setPopupInfo(group),
          })
        }
      })

      setCaoOfficeMarkers([...individualMarkers, ...groupedMarkers])
    }
  }, [caoDeviceDatas, deviceSettingsInfos])

  if (caoDeviceDatasError) {
    return <Typography>No data!</Typography>
  }

  if (!caoDeviceDatas) {
    return <CircularProgress />
  }

  const mapBoxUsername = process.env.REACT_APP_MAPBOX_USERNAME || ''
  const mapBoxStyleId = process.env.REACT_APP_MAPBOX_STYLE_ID || ''
  const mapBoxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''

  const mapBoxUrl = `https://api.mapbox.com/styles/v1/${mapBoxUsername}/${mapBoxStyleId}?access_token=${mapBoxAccessToken}`

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Map
        style={{ height: '100vh' }}
        mapStyle={mapBoxUrl}
        initialViewState={{
          longitude: 15,
          latitude: 20,
          zoom: 2,
        }}
      >
        {caoOfficeMarkers.length > 0 && (
          <>
            <MapHandler caoOfficeMarkers={caoOfficeMarkers} />
            {popupInfo && (
              <CaoPopup
                device={Array.isArray(popupInfo) ? undefined : popupInfo}
                devices={Array.isArray(popupInfo) ? popupInfo : undefined}
                closePopup={() => setPopupInfo(null)}
              />
            )}
          </>
        )}
        <NavigationControl />
      </Map>
    </Box>
  )
}
