import { SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { DeviceSettingsInfo } from '../common-code-react/common-types/device/deviceSettings'
import { fetcher } from '../common-code-react/utils/fetcher'

export function useCaoDeviceSettingsInfos(): SWRResponse<DeviceSettingsInfo[]> {
  const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL || '' : ''
  const url = '/api/caodevices/'
  const fullUrl = encodeURI(`${baseUrl}${url}`)

  return useSWRImmutable(fullUrl, fetcher<DeviceSettingsInfo[]>())
}
