import React from 'react'
import { Popup } from 'react-map-gl'
import { Link, Stack, Typography } from '@mui/material'
import { getAverageLatitude, getAverageLongitude, getLatitude, getLongitude } from '../utils/calculations'
import { DeviceSettings } from '../common-code-react/common-types/device/deviceSettings'
import { CaoDeviceData } from '../common-code-react/common-types/products/cao'

export type DeviceInfo = {
  deviceSettings?: DeviceSettings
  caoDeviceData: CaoDeviceData
}

interface DashboardLinkProps {
  device: DeviceInfo
}

function DashboardLink({ device }: DashboardLinkProps): JSX.Element {
  return (
    <Stack direction='column' spacing={1}>
      <Link
        fontSize={16}
        href={`${process.env.REACT_APP_CAO_DASHBOARD_URL}?id=${device.caoDeviceData.sensorId}`}
        target='_blank'
      >
        <Typography color='black' fontSize={16}>
          {device.deviceSettings?.nameInfo?.name || device.caoDeviceData.name || 'N/A'}
          {` - ${device.caoDeviceData.sensorId}`}
        </Typography>
      </Link>
    </Stack>
  )
}

interface Props {
  device?: DeviceInfo
  devices?: DeviceInfo[]
  closePopup: () => void
}

export function CaoPopup({ device, devices, closePopup }: Props): JSX.Element {
  return (
    <Popup
      anchor='top'
      longitude={
        device
          ? getLongitude(device)
          : getAverageLongitude(devices && Array.isArray(devices) ? devices.map((device) => device) : [])
      }
      latitude={
        device
          ? getLatitude(device)
          : getAverageLatitude(devices && Array.isArray(devices) ? devices.map((device) => device) : [])
      }
      onClose={closePopup}
    >
      <>
        {devices && (
          <Stack direction='column' spacing={2}>
            {devices.map((d, i) => {
              return <DashboardLink key={i} device={d} />
            })}
          </Stack>
        )}
        {device && <DashboardLink device={device} />}
      </>
    </Popup>
  )
}
