import { DeviceInfo } from '../components/CaoPopup'

export function getLatitude(deviceInfo: DeviceInfo): number {
  return deviceInfo.deviceSettings?.geoLocationInfo?.lat || deviceInfo.caoDeviceData.latitude?.value || 0
}

export function getLongitude(deviceInfo: DeviceInfo): number {
  return deviceInfo.deviceSettings?.geoLocationInfo?.lng || deviceInfo.caoDeviceData.longitude?.value || 0
}

export function simpleDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
  return Math.sqrt(Math.pow(lat2 - lat1, 2) + Math.pow(lng2 - lng1, 2)) * 100000
}

export function getAverageLongitude(deviceInfos: DeviceInfo[]): number {
  const average =
    deviceInfos.map((deviceInfo) => getLongitude(deviceInfo)).reduce((a, b) => a + b, 0) / deviceInfos.length || 0
  return average
}

export function getAverageLatitude(deviceInfos: DeviceInfo[]): number {
  const average =
    deviceInfos.map((deviceInfo) => getLatitude(deviceInfo)).reduce((a, b) => a + b, 0) / deviceInfos.length || 0
  return average
}
